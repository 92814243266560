<!-- echarts basic line -->
<template>
  <div class="echarts_wrap">
    <div class="title">{{ el.title }}</div>
    <div class="left">
        <div class="basic-bar" ref="wrapper"></div>
    </div>
    <ul v-if="el.desc && el.desc.contents.length > 0">
        <li v-for="(item, index) in el.desc.contents" :key="index">
          {{ item }}
        </li>
      </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chart: null,
      option: null
    };
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    el: {
      type: Object,
      default: null
    }
  },
  methods: {
    init() {
      let wrapper = this.$refs["wrapper"];
      this.chart = this.$echarts.init(wrapper, "light");
    },
    // 过滤数据
    filterData(data) {
      return data;
    },
    // 设置数据
    setData(data) {
      this.option = data;
      // console.log('折线图数据',data);
      this.$nextTick(() => {
        this.init();
        setTimeout(() => {
          this.draw(data);
        }, 300);
      });
    },
    draw(data) {
      if (data && this.chart && "function" == typeof this.chart.setOption) {
        this.chart.clear();
        this.chart.setOption(data);

        window.addEventListener("resize", () => {
          this.chart.resize();
        });
      }
    }
  },
  watch: {
    info: {
      immediate: true,
      handler: function(val, oldVal) {
        if (!val) {
          return false;
        }

        let data = this.filterData(val);
        this.setData(data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.echarts_wrap {
  .basic-bar {
    width: 100% !important;
    background: #fff;
    min-height: 400px;
  }
  .content_wrap {
    width: 100%;
  }
  ul {
    width: 100%;
    border: 2px solid #ccc;
    min-height: 300px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      padding: 10px;
      width: 100%;
      border-bottom: 2px solid #ccc;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .title {
    height: 0.84rem;
    background: #57d0ac;
    border-radius: 0 0 70px 70px;
    line-height: 0.84rem;
    text-align: center;
    font-size: 0.3rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
  }
}
</style>
