<!-- echarts basic guage -->
<template>
  <div class="echarts_wrap">
    <div class="title" v-if="el.title == '性格倾向'">
      心理特征与行为特点分析
    </div>
    <div v-else class="title">{{ el.title }}</div>
    <div class="content_wrap">
      <div class="left">
        <div class="basic-guage" ref="wrapper"></div>
      </div>
      <ul class="text table_text" v-if="el.tables">
        <li v-for="(item, index) in el.tables" :key="index">
          {{ item }}
        </li>
      </ul>
      <div class="text" v-if="el.desc" v-html="el.desc"></div>
      <div class="right" v-if="el.rightPic">
        <gauge :info="el.rightPic.data" :el="el.rightPic"></gauge>
      </div>
    </div>
  </div>
</template>

<script>
import gauge from "@/components/echarts/gauge2/index";
export default {
  components: {
    gauge
  },
  data() {
    return {
      chart: null,
      option: null
    };
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    el: {
      type: Object,
      default: null
    }
  },
  mounted() {
    // console.log("左右脑--", this.el);
  },
  methods: {
    init() {
      let wrapper = this.$refs["wrapper"];
      this.chart = this.$echarts.init(wrapper, "light");
    },
    // 过滤数据
    filterData(data) {
      return data;
    },
    // 设置数据
    setData(data) {
      this.option = data;
      // console.log('折线图数据',data);
      this.$nextTick(() => {
        this.init();
        setTimeout(() => {
          this.draw(data);
        }, 300);
      });
    },
    draw(data) {
      if (data && this.chart && "function" == typeof this.chart.setOption) {
        this.chart.clear();
        this.chart.setOption(data);

        window.addEventListener("resize", () => {
          this.chart.resize();
        });
      }
    }
  },
  watch: {
    info: {
      immediate: true,
      handler: function(val, oldVal) {
        if (!val) {
          return false;
        }

        let data = this.filterData(val);
        this.setData(data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.echarts_wrap {
  .title {
    height: 0.84rem;
    background: #57d0ac;
    border-radius: 0 0 70px 70px;
    line-height: 0.84rem;
    text-align: center;
    font-size: 0.3rem;
    font-weight: 600;
    color: #fff;
    // margin-bottom: 20px;
  }
  .content_wrap {
    width: 100%;
    .left,
    .right {
      width: 100%;
    }
  }
  .basic-guage {
    width: 100% !important;
    background: #fff;
    height: 320px;
  }
  .text {
    width: 100%;
    line-height: 28px;
  }
  ul.table_text {
    display: flex;
    flex-wrap: wrap;
    background: #ffb4b4;
    margin-top: 0.2rem;
    li {
      color: #000;
      flex-basis: 25%;
      border: 1px solid #fffbfb;
      padding: 8px 14px;
      text-align: center;
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        border-top: none;
        border-bottom: none;
      }
      &:nth-child(2n + 1),
      &:nth-child(2),
      &:nth-child(6),
      &:nth-child(10) {
        border-right: none;
      }
    }
  }
}
</style>
