export const pie = (el) => {
    return {
        type: el.type,
        title: el.title,
        data: {
          // backgroundColor: "#2c343c",
          title: {
            text: el.pic_title || "",
            left: "center",
            top: 20,
            textStyle: {
              color: "#333",
              fontSize: '14'
            }
          },

          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c}个专业"
          },
          
          series : [
              {
                  name: '学科',
                  type: 'pie',
                  radius : '60%',
                  center: ['50%', '56%'],
                  selectedMode: 'single',
                  data: el.data,
                  itemStyle: {
                      emphasis: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
        }
      };
}