<template>
    <div class="wraper">
      <!-- line -->
      <basic-line v-if="type=='line' && info.show" :info="data" :el="el"></basic-line> 
      <basic-bar v-if="type=='bar'" :info="data" :el="el"></basic-bar>
      <basic-pie v-if="type=='pie'" :info="data" :el="el"></basic-pie>
      <basic-radar v-if="type=='leida'" :info="data" :el="el"></basic-radar>
      <gauge v-if="(type=='yibiaopan' || type=='zuoyounao') && info.show" :info="data" :el="el"></gauge>
      <basic-bar v-if="type=='tiaoxingtu'" :info="data" :el="el"></basic-bar>
      <list v-if="type=='list'" :info="data" :el="el"></list>
      <course-text v-if="type=='xueke_text'" :info="data" :el="el"></course-text>
    </div>
</template>

<script>
import basicLine from '@/components/echarts/line/index';
import basicBar from '@/components/echarts/bar/index';
import basicPie from '@/components/echarts/pie/index';
import basicRadar from '@/components/echarts/radar/index';
import gauge from '@/components/echarts/gauge/index';
import list from '@/components/echarts/list/index';
import courseText from '@/components/echarts/courseText/index';

export default {
    data () {
        return {
            gotData: false,
            type: '',
            data: null,
            el: null,
        }
    },
    props: {
        info: {
            default: null
        }
    },
    methods: {
        filterData ( data ) {
            return data;
        },
        setData ( data ) {
            this.gotData = true;
            this.type = data['type'];
            this.data = data['data'];
            this.el = data;
        }   
    },
    watch: {
        'info': {
            immediate: true,
            handler: function ( val, oldVal ) {
                // console.log('模块',val);
                if ( !val ) {
                    return false;
                }
                let data = this.filterData(val);
                this.setData(data);
            }          
        }
    },
    components: {
        basicLine,
        basicBar,
        basicPie,
        basicRadar,
        gauge,
        list,
        courseText,
    }
}
</script>

<style scoped>
.wraper{
   width: 100%;
}
</style>