<!-- echarts basic guage -->
<template>
  <div class="echarts_wrap">
    <div class="basic-guage-2" ref="wrapper"></div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      chart: null,
      option: null
    };
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    el: {
      type: Object,
      default: null
    }
  },
  methods: {
    init() {
      let wrapper = this.$refs["wrapper"];
      this.chart = this.$echarts.init(wrapper, "light");
    },
    // 过滤数据
    filterData(data) {
      return data;
    },
    // 设置数据
    setData(data) {
      this.option = data;
      // console.log('折线图数据',data);
      this.$nextTick(() => {
        this.init();
        setTimeout(() => {
          this.draw(data);
        }, 300);
      });
    },
    draw(data) {
      if (data && this.chart && "function" == typeof this.chart.setOption) {
        this.chart.clear();
        this.chart.setOption(data);

        window.addEventListener("resize", () => {
          this.chart.resize();
        });
      }
    }
  },
  watch: {
    info: {
      immediate: true,
      handler: function(val, oldVal) {
        if (!val) {
          return false;
        }

        let data = this.filterData(val);
        this.setData(data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .basic-guage-2 {
    width: 100% !important;
    background: #fff;
    height: 300px;
    padding: 20px 0 0 0;
  }

</style>
