
//-仪表盘
export const twoGauge = el => {
  el.data[0].value = parseInt((100 - el.data[0].value).toString().split('-')[0])
  return {
    type: el.type,
    title: el.title,
    desc: el.desc || null,
    tables: el.tables,
    data: {
      title: {
        text: el.pic_title || "",
        left: "center",
        top: "20",
        textStyle:{
          fontSize: '14'
        }
      },
      // backgroundColor: "#1b1b1b",
      tooltip: {
        formatter: function(v){
            var value = v.value;
            if(v.name == '左脑'){
                value = 100 - v.value;
            }
            return v.seriesName +'<br/>'+v.name+' '+value
        }
      },
      series: [
        {
          name: el.title,
          type: "gauge",
          center: ["50%", "75%"], // 默认全局居中
          radius: "98%",
          min: 0,
          max: 100,
          startAngle: 200,
          endAngle: 66,
          axisLine: {
            // 坐标轴线
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: [[0.82, "#aaa900"]],
              shadowColor: "#fff", //默认透明
              shadowBlur: 10,
              width: 25 //线条宽度
            }
          },
          axisLabel: {
            textStyle: {
              // 属性lineStyle控制线条样式
              fontWeight: "bolder",
              color: "#000",
              shadowColor: "#fff", //默认透明
              shadowBlur: 10
            },
            formatter: function(v) {
              switch (v + "") {
                case "0":
                  return '100'+el.left;
                default:
                  return (v - 100).toString().split("-")[1];
              }
            }
          },
          splitLine: {
            // 分隔线
            length: 8, // 属性length控制线长
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              width: 2,
              color: "#fff",
              shadowColor: "#fff", //默认透明
              shadowBlur: 4
            }
          },
          pointer: {
            width: 6,
            shadowColor: "#fff", //默认透明
            shadowBlur: 5
          },
          title: {
            show: false
          },
          detail: {
            show: false
          },
          data: [el.data[0]]
        },
        {
          min: 0,
          max: 100,
          startAngle: 90,
          endAngle: -45,
          name: el.title,
          type: "gauge",
          center: ["50%", "75%"], // 默认全局居中
          radius: "98%",
          title: {
            show: false
          },
          detail: {
            show: false
          },
          splitLine: {
            // 分隔线
            length: 8, // 属性length控制线长
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              width: 2,
              color: "#fff",
              shadowColor: "#fff", //默认透明
              shadowBlur: 4
            }
          },
          pointer: {
            width: 6,
            shadowColor: "#fff", //默认透明
            shadowBlur: 5
          },
          axisLine: {
            // 坐标轴线
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: [[0.82, "#9260ff"]],
              shadowColor: "#fff", //默认透明
              shadowBlur: 10,
              width: 25 //线条宽度
            }
          },
          axisLabel: {
            textStyle: {
              // 属性lineStyle控制线条样式
              fontWeight: "bolder",
              color: "#000",
              shadowColor: "#fff", //默认透明
              shadowBlur: 5
            },
            formatter: function(v) {
              switch (v + "") {
                case "100":
                  return el.right+'100';
                default:
                  return v;
              }
            }
          },
          data: [el.data[1]]
        }
      ]
    }
  };
};
