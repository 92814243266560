export const bar = el => {
  return {
    type: el.type,
    title: el.title,
    data: {
      title: {
        text: el.pic_title || "",
        left: "center",
        textStyle:{
          fontSize: '14'
        }
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01]
      },
      xAxis: [
        {
          type: "category",
          axisTick: { show: false },
          data: el.x
        }
      ],
      yAxis: [
        {
          type: "value"
        }
      ],
      series: [
        {
          name: "",
          type: "bar",
          barWidth: 18, //柱图宽度
          data: el.y,
        }
      ]
    }
  };
};
