import {
  bar,
  line,
  gauge,
  list,
  pie,
  radar,
  tiaoxingtu,
  twoGauge,
  courseText,
} from "./echartsConfig";

const echartsTypes = [
  {
    name: "条形图",
    key: "tiaoxingtu"
  },
  {
    name: "折线图",
    key: "line"
  },
  {
    name: "柱状图",
    key: "bar"
  },
  {
    name: "饼图",
    key: "pie"
  },
  {
    name: "专业表格",
    key: "list"
  },
  {
    name: "雷达图",
    key: "leida"
  },
  {
    name: "条形图",
    key: "tiaoxingtu"
  },
  {
    name: "左右脑",
    key: "zuoyounao"
  }
];

// 职业测评顺序
const zhiyeOrderList = [
  "youshi_fenxi",
  "xingqu_fenxi",
  "xingqu_youshi_leida",
  "youshi_xingqu_line",
  "xingge_qingxiang",
  "renshi_yuanze",
  "juece_fangshi",
  "juece_sudu",
  "si_li",
  "zuoyou_nao",
  "zhineng",
  "zhuanye",
  "gold",
  "silver",
  "gold_tuijian_list",
  "silver_tuijian_list"
];

const xuankeOrderList = [
  "youshi",
  "xingqu",
  "xingqu_youshi_leida",
  "youshi_xingqu_line",
  "all"
];
const learnStatusOrderList = ["user_leida", "user_bar", "desc"];

let showDataList = [];
let orderList = [];
//处理数据
const resetData = function(tabType, data) {
  console.log(tabType);

  switch (tabType) {
    case "职业规划测评":
      showDataList = new Array(zhiyeOrderList.length).join(",").split(",");
      orderList = zhiyeOrderList;
      break;
    case "新高考选科测评":
      showDataList = new Array(xuankeOrderList.length).join(",").split(",");
      orderList = xuankeOrderList;
      break;
    case "学习状态":
      showDataList = new Array(learnStatusOrderList.length)
        .join(",")
        .split(",");
      orderList = learnStatusOrderList;
      break;
    default:
      showDataList = new Array(zhiyeOrderList.length).join(",").split(",");
      orderList = zhiyeOrderList;
      break;
  }
  orderList.forEach((item, index) => {
    for (let key in data) {
      const el = data[key];
      if (key == item) {
        switch (el.type) {
          case "leida": //雷达
            const showleidaEl = radar(el);
            showDataList[index] = showleidaEl;
            break;
          case "line": //折线图
            const showLineEl = line(el);
            showDataList[index] = showLineEl;
            break;
          case "bar": //柱状图
            const showBarEl = bar(el);
            showDataList[index] = showBarEl;
            break;
          case "tiaoxingtu": //条形图
            const showTiaoxingtuEl = tiaoxingtu(el);
            showDataList[index] = showTiaoxingtuEl;
            break;
          case "pie":
            const showPieEl = pie(el);
            showDataList[index] = showPieEl;
            break;
          case "yibiaopan":
            const showGaugeEl = gauge(el);
            showDataList[index] = showGaugeEl;
            break;
          case "list":
            const showListEl = list(el);
            showDataList[index] = showListEl;
            break;
          case "zuoyounao":
            const showTwoGaugeEl = twoGauge(el);
            showDataList[index] = showTwoGaugeEl;
            break;
            case "xueke_text":
                const showXuekeTextEl = courseText(el);
                showDataList[index] = showXuekeTextEl;
            break; 
          default:
            break;
        }
      }
    }
  });
  
  if (showDataList.length > 0) {
    showDataList.forEach(item => {
      if (typeof item == "object") {
        item.show = true;
      }
    });
  }

  let aa = reDealData(showDataList);

  return showDataList;
};

//重新整理数据，处理左右两图的情况
function reDealData(Data) {
  return Data.map((item, index) => {
    if (item.title == "优势与兴趣匹配分析" && item.type == "leida") {
      item.rightPic = Data[index + 1];
      Data[index + 1].show = false;
    }

    if (item.title == "学习能力与兴趣匹配分析" && item.type == "leida") {
      item.rightPic = Data[index + 1];
      Data[index + 1].show = false;
    }
    if (item.title == "学习状态" && item.type == "leida") {
      item.rightPic = Data[index + 1];
      Data[index + 1].show = false;
    }
    return item;
  });
}

export default resetData;
