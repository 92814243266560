
import { getHslArray } from "@/utils/randomColor";
export const radar = el => {
  // el.data.length > 1
  //   ? (el.data[1].areaStyle = {
  //       normal: {
  //         opacity: 0.9,
  //         color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
  //           {
  //             color: "#B8D3E4",
  //             offset: 0
  //           },
  //           {
  //             color: "#72ACD1",
  //             offset: 1
  //           }
  //         ])
  //       }
  //     })
  //   : null;
  return {
    type: el.type,
    title: el.title,
    desc: el.desc,
    data: {
      title: {
        text: el.pic_title || "",
        left: "center",
        textStyle:{
          fontSize: '14'
        }
      },
      // legend: el.legend,
      tooltip: {
        trigger: "axis"
      },
      radar: [
        {
          indicator: el.indicator,
          center: ["50%", "50%"],
          radius: 98,
          startAngle: 90,
          splitNumber: 5,
          shape: "circle",
          name: {
            formatter: "{value}",
            textStyle: {
              color: "#333"
            }
          },
          splitArea: {
            areaStyle: {
              color: [
                "rgba(243, 225, 63, 0.1)",
                "rgba(243, 225, 63, 0.2)",
                "rgba(243, 225, 63, 0.3)",
                "rgba(243, 225, 63, 0.4)",
                "rgba(243, 225, 63, 0.5)"
              ],
              shadowColor: "rgba(0, 0, 0, 0.3)",
              shadowBlur: 10
            }
          },
          axisLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.5)"
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.5)"
            }
          }
        }
      ],
      series: [
        {
          type: "radar",
          tooltip: {
            trigger: "item"
          },
          itemStyle: {
            emphasis: {
              color: "#333",
              lineStyle: {
                width: 3, //hover效果
                color: getHslArray()[0]
              }
            }
          },
          data: el.data.map((item, index) => {
            item.lineStyle = {
              normal: {
                width: 3,
                color: index == 0 ? "#0821a2" : "#a70008"
              }
            };
            return item;
          })
        }
      ]
    }
  };
};
