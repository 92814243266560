//学科文本
export const courseText = el => {
    let newArr = [];
    el.xueke.forEach((item,index) => {
        for(let key in el.desc){
            if(item == key){
                newArr.push({
                    label: item+' '+el.point[index],
                    text: el.desc[key],
                })
            }
        }
    })
    // console.log('学科rext',newArr);
    return {
        type: el.type,
        data: newArr
    };
}