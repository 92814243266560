<!-- echarts basic line -->
<template>
  <div class="echarts_list_wrap">
    <div class="title">{{ el.title || "推荐专业" }}</div>
    <template v-for="(item, index) in option.data">
      <div class="list" ref="wrapper" :key="index">
        <div>
          <div class="label">专业名称</div>
          <div>{{ item.profession }}</div>
        </div>
        <div>
          <div class="label">主要课程</div>
          <div>{{ item.course }}</div>
        </div>
        <div>
          <div class="label">就业方向</div>
          <div>{{ item.content }}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chart: null,
      option: null
    };
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    el: {
      type: Object,
      default: null
    }
  },
  methods: {
    // 过滤数据
    filterData(data) {
      return data;
    },
    // 设置数据
    setData(data) {
      this.option = data;
    }
  },
  watch: {
    info: {
      immediate: true,
      handler: function(val, oldVal) {
        if (!val) {
          return false;
        }

        let data = this.filterData(val);
        this.setData(data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.echarts_list_wrap {
  .title {
    height: 0.84rem;
    background: #deb65f;
    border-radius: 0 0 70px 70px;
    line-height: 0.84rem;
    text-align: center;
    font-size: 0.3rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px;
  }

  .list {
    width: 100% !important;
    background: #fff;
    min-height: 230px;
    padding: 22px 0;
    border-bottom: 1px dashed #ccc;
    &:last-child{
        border-bottom: none;
    }
    > div {
      display: flex;
      margin-bottom: 10px;
      .label {
        background: #de5f5f;
        color: #fff;
        flex-basis: 22%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > div {
        min-height: 45px;
      }
      div:nth-child(2) {
        flex-basis: 90%;
        padding: 5px 14px;
        line-height: 24px;
      }
    }
  }
}
</style>
