<template>
  <div class="my_evaluations_page">
    <div class="content" v-if="userInfo">
      <div class="page_title">
        {{ type }}
      </div>
      <div
        class="no_tip"
        v-if="
          !userInfo.test_status.zhiye &&
            !userInfo.test_status.gaokao &&
            !userInfo.test_status.xuexi
        "
      >
        您还未进行测评
      </div>

      <div
        v-if="
          userInfo && userInfo.test_status.zhiye && tabActiveName == 'first'
        "
        label="职业规划测评"
      >
        <div class="test_result">
          <div v-for="(item, index) in showEchartsData" :key="index">
            <module v-if="item.show" class="test_result_item" :info="item"></module>
          </div>
        </div>
      </div>
      <div
        v-if="
          userInfo && userInfo.test_status.gaokao && tabActiveName == 'second'
        "
        label="选科测评"
      >
        <div class="test_result">
          <div v-for="(item, index) in showEchartsData" :key="index">
            <module v-if="item.show" :info="item" class="test_result_item"></module>
          </div>
        </div>
      </div>
      <div
        v-if="
          userInfo && userInfo.test_status.xuexi && tabActiveName == 'third'
        "
        label="学习状态测评"
      >
        <div class="test_result">
          <div v-for="(item, index) in showEchartsData" :key="index">
            <module v-if="item.show" :info="item" class="test_result_item"></module>
          </div>
          <!-- 介绍文本 -->
          <my-tab-panel v-if="showEchartsData.length > 0"></my-tab-panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/components/echarts/module";
import resetData from "@/components/echarts/resetData";
import MyTabPanel from "@/components/echarts/tabPanel";
import { mapState } from "vuex";

export default {
  components: { module, MyTabPanel },
  data() {
    return {
      activeName: "", //左侧导航
      tabActiveName: "",
      type: "",
      showEchartsData: [],
      testType: ""
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.userinfo
    })
  },
  async mounted() {
    this.testType = this.$route.params.type || "";
    if (this.testType == "learning") {
      this.tabActiveName = "third";
    } else if (this.testType == "course") {
      this.tabActiveName = "second";
    } else {
      this.tabActiveName = "first";
    }

    this.fetchTestResult();
  },
  methods: {
    handleClick(tab, event) {
      this.showEchartsData = [];
      this.fetchTestResult();
    },
    //获取测评结果
    async fetchTestResult() {
      if (this.tabActiveName == "second") {
        this.type = "新高考选科测评";
      } else if (this.tabActiveName == "third") {
        this.type = "学习状态";
      } else {
        this.type = "职业规划测评";
      }

      const apiUrl = "/api/question/result/";
      try {
        const res = await this.$axios.post(apiUrl, { type: this.type });
        if (res.data.errno === 0) {
          const Data = res.data.results.result;
          this.showEchartsData = resetData(this.type, Data);
          console.log("showEchartsData---", this.showEchartsData);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.my_evaluations_page {
  .content {
    min-height: 100vh;
    .test_result_item{
      padding:0.35rem 0.3rem;
      margin-bottom: 0.2rem;
      background: #fff;
    }
    .page_title {
      padding:0.2rem 0.3rem;
      font-size: 0.3rem;
      font-weight: 600;
      background: #fff;
      border-bottom: 1px solid #e2e2e2;
    }
    /deep/ .el-tabs__active-bar {
      background-color: #910000 !important;
    }
    .no_tip {
      text-align: center;
      margin-top: 100px;
      color: #666;
    }
    .el-tabs__header {
      margin-bottom: 30px;
    }
  }

}
</style>
