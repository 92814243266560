<template>
  <ul class="course_text">
    <li v-for="(item, index) in info" :key="index">
      <div class="label">{{ item.label }}</div>
      <div class="text">{{ item.text }}</div>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    info: {
      type: Array,
      default: null
    },
    el: {
      type: Object,
      default: null
    }
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
ul.course_text {
  li {
    display: flex;
    margin-bottom: 22px;
    line-height: 24px;
    .label {
      flex-basis: 22%;
      background: #ffbe6e;
      margin-right: 15px;
      display: flex;
      font-weight: 600;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
    }
    .text {
      flex-basis: 78%;
    }
  }
}
</style>
