import { getHslArray } from "@/utils/randomColor";

//-仪表盘
export const gauge = el => {
    var showVal = "";
    var showName = ""
    if(el.value == el.left){
        showVal = 30;
    }else if(el.value == el.right){
        showVal = 80;
    }else {
        showVal = 50;
    }
    showName = el.value;

  return {
    type: el.type,
    title: el.title,
    desc: el.desc,
    tables: el.tables,
    data: {
      title: {
        text: el.pic_title || "",
        left: "center",
        top: "20",
        textStyle:{
          fontSize: '14'
        }
      },
      // backgroundColor: "#1b1b1b",
      tooltip: {
        formatter: "{a} <br/>{b}"
      },
      series: [
        {
          name: el.pic_title,
          type: "gauge",
          center: ["50%", "73%"], // 默认全局居中
          radius: "100%",
          min: 0,
          max: 100,
          startAngle: 180,
          endAngle: 0,
          splitNumber: 2,
          axisLine: {
            // 坐标轴线
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: [
                [0.5, getHslArray()[0]],
                [1, getHslArray()[0]]
              ],
              width: 12 //线条粗细
            }
          },
          axisTick: {
            // 坐标轴小标记
            length: 12, // 属性length控制线长
            lineStyle: {
              // 属性lineStyle控制线条样式
              color: "auto"
            }
          },
          axisLabel: {
            textStyle: {
              // 属性lineStyle控制线条样式
              fontWeight: "bolder"
            },
            formatter: function(v) {
              switch (v + "") {
                case "0":
                  return el.left;
                case "50":
                  return "中间";
                case "100":
                  return el.right;
              }
            }
          },
          splitLine: {
            // 分隔线
            length: 15, // 属性length控制线长
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              width: 4,
              color: "#fff",
              shadowColor: "#fff", //默认透明
              shadowBlur: 10
            }
          },
          pointer: {
            width: 4,
            shadowColor: "#fff", //默认透明
            shadowBlur: 5
          },
          title: {
            show: false
          },
          detail: {
            show: false
          },
          data: [{ value: showVal, name: showName }]
        }
      ]
    }
  };
};
