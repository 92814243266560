
export const line = el => {
  const common = {
    smooth: true,
    type: "line"
  };
  return {
    type: el.type,
    title: el.title,
    data: {
      title: {
        text: el.pic_title || "",
        left: "center",
        textStyle:{
          fontSize: '14'
        }
      },
      tooltip: {},
      xAxis: {
        type: "category",
        boundaryGap: false,
        axisLabel: {
          //底部文字倾斜
          interval: 0,
          rotate: 38
        },
        data: el.xAxis
      },
      yAxis: {
        type: "value"
      },
      series: el.series.map((item, index) => {
        item.smooth = true;
        item.symbol = "circle";
        item.itemStyle = {
          normal: {
            borderWidth: 2.6,
            borderColor: index == 0 ? "#0821a2" : "#a70008", //拐点
            color: index == 0 ? "#0821a2" : "#a70008" //拐点
          }
        };
        item.lineStyle = {
          normal: {
            width: 2.4, //设置线条粗细
            color: index == 0 ? "#0821a2" : "#a70008"
          }
        };
        return item;
      })
    }
  };
};
