<template>
  <div class="learn_status_text">
    <van-tabs v-model="activeTextName" :ellipsis="false" @click="handleClick">
      <van-tab title="学习目标" name="first">
        <p>
          学生为何要制定学习目标？
          俗话说：成功的人生就是一个好的目标体系，当目标完全融入生活的时候，人生目标的达成就只剩下时间的问题了。学习目标是学习状态的动力系统，只有设定合理的长期与短期目标才能产生持久有力的动力。
        </p>
        <ul>
          <li>
            0-49分（低）：你的数值低于社会平均值，学习主动性和积极性不高。
          </li>
          <li>
            50-69分（中）：你的数值处于社会平均值，说明具有一定的学习主动性和积极性。
          </li>
          <li>
            70-100分（高）：你的数值高于社会平均值，说明具有较高的学习主动性和积极性。
          </li>
        </ul>
        <p>提升学习目标的方法：</p>
        <ul>
          <li>
            1、明确职业方向，加强职业探索。职业生涯规划有助于确立个人发展目标，通过个人发展目标来设定学习目标，例如我的理想职业是什么，哪些大学的哪些专业可以实现我的人生目标，从现在起我要提升多少分才能进入我的理想大学和理想专业，想要提升分数需要提升哪些学科的分数，自己目前在哪些方面最容易提升分数等等。
          </li>
          <li>
            2、设定合理的长期与短期目标。长期目标够远大，短期目标可实现。目标要具体，最好有具体的数字，比如“我要进步”，这还不够具体，具体的应该是“我要进入前十名”。目标要有期限，比如“我要学好英语”，可能你一辈子也学不好，正确的是本学期我一定要把英语成绩提升到95分。制定实现目标的行动计划，为了把英语成绩提升到95分，就要做切实可行的计划：每天早晨背会3个单词，中午听30分钟录音，晚上学会两句标准的句子，天天坚持。目标视觉化，把自己制定的目标写在墙上经常看，目标自然深入潜意识，自我监督，或请别人监督。整合资源，为了实现目标可以寻求哪些帮助，比如为了提高英语成绩我可以寻求哪些资源？培训班？请家教？不断地请教老师？请同学帮助？每天检查目标，衡量进度。今天我都做了哪些事情？这些事情都有利于目标的实现吗？
          </li>
        </ul>
      </van-tab>
      <van-tab title="学习态度" name="second">
        <p>
          学习态度是指学习者对学习较为持久的肯定或否定的行为倾向或内部反应的准备状态。它通常可以从学生对待学习的注意状况、情绪状况和意志状态等方面加以判定和说明。学生的学习态度，具体又可包括对待课程学习的态度、对待学习材料的态度以及对待教师、学校的态度等。
        </p>
        <ul>
          <li>
            0-56分（低）：你的数值低于社会平均值，和学习没有建立良好的关系，对待学习有些不良情绪。
          </li>
          <li>
            57-74分（中）：你的数值处于社会平均值，能够比较认真的对待课业，合理的安排时间，上课也能够认真做笔记。
          </li>
          <li>
            75-100分（高）：你的数值高于社会平均值，对待学习有一种坚持不懈和独立自主的韧劲，不畏学习中的艰难，热爱并且能够享受学习过程。
          </li>
        </ul>
        <p>端正学习态度的方法：</p>
        <ul>
          <li>
            1、首先，要对学习有一个正确的认识，明白学习的意义是提升自己的人生观，价值观，要相信学习能够改变命运，提升自我的思维方式。未来社会的文盲不是缺乏知识，而是不会学习，很多孩子会说比尔盖茨、乔布斯都没有上过大学，但事实是他们不是没有上过大学，而是他们上过大学之后发现大学的教育不适合他们，但是这只是少部分人，因为像比尔盖茨和乔布斯这样的少数人虽然没有上完大学，但是事实上他们却是自学能力和目标感超强的人，所以大部分人还是要接受高等教育。
          </li>
          <li>
            2、要懂得坚持和迎难而上。很多学生有了一点成绩就骄傲自满，这是学习最大的劲敌，坚持意味着始终如一的执行自己设定的计划，意味着在学习中对偶尔遇到的难题不轻言放弃。
          </li>
          <li>
            3、上课认真听讲，记笔记。有些学生上课不认真听讲，耍小聪明，认为依靠自学就可以提升成绩，老师讲的不仅仅是知识，更有学习方法和应试规律的总结，是老师多年教学经验的精华。
          </li>
          <li>
            4、胜不骄，败不馁。俗话说的好:胜败乃兵家常事，失败并不可耻，令人可耻的是不堪一击的薄弱意志，颓废萎靡的懦夫行为，当我们遇到挫折与失败时，决不能灰心丧气，破罐子破摔，而应当检查自己做的事情，从中找出原因，不断总结，迎难而上，就会从失败走向成功。
          </li>
        </ul>
      </van-tab>
      <van-tab title="学习习惯" name="third"
        ><p>
          学习习惯，是在学习过程中经过反复练习形成并发展，成为一种个体需要的自动化学习行为方式。养成良好的学习习惯，有利于激发学生学习的积极性和主动性；有利于形成学习策略，提高学习效率；有利于培养自主学习能力；有利于培养学生的创新精神和创造能力，使学生终身受益。一个行为重复4个星期就会成为习惯，重复14个星期就会成为稳固的习惯。
        </p>
        <ul>
          <li>
            0-49分（低）：你的数值低于社会平均值，说明你还没有建立如预习、复习、整理错题等良好的习惯。
          </li>
          <li>
            50-69分（中）：你的数值处于社会平均值，说明你已经初步建立如预习、复习、整理错题等良好的习惯。
          </li>
          <li>
            70-100分（高）：你的数值高于社会平均值，说明你已经长期建立如预习、复习、整理错题等良好的习惯，并能够有效的坚持。
          </li>
        </ul>
        <p>提升成绩的好习惯：</p>
        <ul>
          <li>
            1、阅读自学习惯。自学是获取知识的主要途径，阅读是自学的一种主要形式，通过阅读教科书可以独立领会知识，把握概念本质内涵，分析前后联系，反复推敲，理解教材，深化知识，形成能力。
          </li>
          <li>
            2、课前预习的习惯。听课是一个重要的学习环节，带着问题去听，方向、目的明确，具有主动性，比盲目被动的听要好很多。经过预习，能明确重点、难点，产生疑问，形成探究的欲望。因此，预习是提高听课效率的有效途径。
          </li>
          <li>
            3、做听课笔记的习惯。在听课这个重要环节中，边听边记有助于集中注意力，加深理解，巩固记忆。随着中学课程内容的增多和复杂化，记笔记还有助于抓住重点。如果因时间限制，当堂记得东西较凌乱，那么课后还要进行整理，使之全面，有条理。
          </li>
          <li>
            4、课后复习的习惯。复习的目的是“温故而知新”。复习要与遗忘做斗争，德国心理学家创制的“遗忘速度曲线”表明，遗忘是有规律的，即先快后慢，刚记住的东西最初几小时内遗忘最快，两天后就较缓慢。因此要巩固所学知识，要及时复习，加以强化，养成习惯。
          </li>
          <li>
            5、练后反思的习惯。反思是解题之后的主要环节。一般来说，习题做完之后，要从五个层次反思：A、怎样做出来的？想解题采用的方法。B、为什么这样做？想解题依据的原理。C、为什么想到用这种方法？想解题基本思路。D、有无其它方法？哪种方法更好？对比归纳，思维求异。E、能否把题目变通一下，变成另一类题目？一题多变，促使思维发散。
          </li>
        </ul></van-tab
      >
      <van-tab title="学习效率" name="fourth">
        <p>
          学习效率是学习快慢的表示形式。做同一件事，你需要2个小时才能完成，而我只需要半个小时就可以完成，这就是效率。
        </p>
        <ul>
          <li>
            0-58分（低）：你的数值低于社会平均值，学习时你通常事倍功半，经常把老师留给的作业拖到很晚才能完成。
          </li>
          <li>
            59-74分（中）：你的数值处于社会平均值，通常能够准时的完成老师教给的任务，也能留出一定的时间进行课外阅读。
          </li>
          <li>
            75-100分（高）：你的数值高于社会平均值，学习时你通常事半功倍，能够合理的安排好娱乐和学习的时间。
          </li>
        </ul>
        <ul>
          <li>
            1、分解学习任务，使用80/20法则管理学习过程。大家都非常熟悉80/20法则，其实这个法则同样可以运用到学习中来。80%的产出，是由20%的工作带来的。我们在学习中会遇到无从下手的时候，这时我们只需做一两件事，这一两件事是达到目的的关键。通过反复的学习和思考，就会带来在这个问题上的突破。这就是分解学习任务，把学习的重点画出来，着重学习理解。那么学习中普遍的，推演的部分便迎刃而解。例如弹吉他，不应该纠结弹奏的模式，而是认真练习基本的和弦，以及和弦之间的过渡，等练习的连贯之后自然会取得大的进步。
          </li>
          <li>
            2、全神贯乎，番茄学习法提高效率。学习时全神贯注非常重要，可是做起来却很难。你会被突然想起的手机打断，有时也会因为外界的嘈杂而影响。为了增加学习的专注度，使用番茄学习法或许能有一定效果。我们排除一切干扰学习的外界因素，并在短时间内专注一件事，大概持续20--25分钟，然后休息3--5分钟。这样我们在学习时，把学习分成若干个时段，每个时段学习25分钟，休息5分钟。每四个时段多休息一会。这样能使我们在短时间内集中精神完成信息的收集，又不会因为长期的学习感到乏累，提高了学习的有效性。在短暂的休息中，也能得到必要的放松和思考，缓冲学习的知识，避免造成积压。
          </li>
          <li>
            3、通过实践学习，沉浸式学习法。研究显示通过阅读学习只能学到其中10%的知识：通过听和看能学到其中20%的知识；通过演示能学到其中的50%的知识；学完后练习能学到其中70%的知识；学完后立即使用就能学到90%的知识。很明显沉浸式学习法是最有效的学习方法，只有把学习的东西付诸于实践中，才能巩固学习反射，加强学习效果。就像学习踢足球，没有人会通过专业书籍变成优秀的运动员。你必须上场去练习，在比赛中提高踢球的技能。站在场边看十场比赛，不加实践练习，你也学不会一次停球。
          </li>
          <li>
            4、充足睡眠。 睡眠对于学习意义重大。我们在清醒时学习新事物收集新信息，并加强这些记忆。睡眠就是一种创造性记忆的过程，我们清醒时受到的刺激和学习的知识会在睡眠中得到加强。科学家认为，人的大脑是可以改变结构和组织，也就是大脑的可塑性。我们通过睡眠的休息状态，让大脑对学习的新知识新信息进行处理强化,形成知识记忆。
            当然充足的睡眠也是保证未来学习的可持续性,困倦的大脑
            很难集中精神进行学习。保证充足的睡眠也是缓解压力和放松身体的有效方式。毕竟学习是一个需要全身能动的过程。
          </li>
        </ul>
      </van-tab>
      <van-tab title="学习策略和方法" name="five">
        <p>
          学习方法和学习技巧是学习者为了解决某个学习问题或为了使学习过程更有效而采取的某些具体做法或手段。学习策略是对学习方法的选择和使用。常见的学习方法有：划线、做笔记（思维导图）、速记、写提要、PQ4R（预习、设问、阅读、反思、背诵、复习）、提问等等。
        </p>
        <ul>
          <li>
            0-49分（低）：你的数值低于社会平均值，没有掌握学习的规律性以及系统性学习的方法。
          </li>
          <li>
            50-69分（中）：你的数值处于社会平均值，你也许会懂得预习、复习、背诵等简单的策略，但是在知识体系的建立上还有待加强。
          </li>
          <li>
            70-100分（高）：你的数值高于社会平均值，你不光懂得预习、复习、背诵等简单的策略，还能够梳理笔记，运用思维导图等方式建立稳固的知识体系。
          </li>
        </ul>
        <p>提升学习策略和方法的有效手段：</p>
        <ul>
          <li>
            1、思维导图。自己构建的思维导图的基础就是老师课堂上在黑板上书写的板书，通过相同之处互相连接，往往有事半功倍之用。所以建立思维导图之前，首先要仔细记录老师的板书，了解他的思维过程，从而更有效的构建自己的框架。在课后，自己在纸上构建，字迹可以不清楚，但自己一定要看懂，做到简洁明快，把自己上一节的知识点或一段时间内的知识点通过树状分叉衍生，做到一法通万法通，在已有的知识点上加强其他知识点的熟练，可以对比分析，通过思维导图不同的分叉记住难点要点。思维导图完成后，要时常翻阅，加深记忆，同时通过自己的提升，修改不合理的部分，使得思维导图更加完善。最终复习时，可以将几张有相同或相反的思维导图相互合并，减少记忆量同时加深记忆痕迹，形成自己独有的思维导图，使得每一个要素，每一个符号都能在自己的知识体系内找到相应的知识。
          </li>
          <li>
            <p>2、PQ4R技术可以这样具体地使用。</p>
            <p>
              预览：快速浏览材料，对材料的基本组织主题和副主题有一个初步的了解。注意标题和小标题，找出你要读的和学习的信息。
            </p>
            <p>
              设问：阅读时自己问自己一些问题。根据标题用“谁”、“什么”、“为什么”、“哪儿”、“怎样”等疑问词提问。
            </p>
            <p>阅读：阅读材料，不要泛泛地做笔记。试图回答自己提出的问题。</p>
            <p>
              反思：通过以下途径，试图理解信息并使信息有意义：①把信息和你已知的事物联系起来；②把课本中的副标题和主要概念及原理联系起来；③试图消除对呈现的信息的分心；④试图用这些材料去解决联想到的类似的问题。
            </p>
            <p>
              背诵：通过大声陈述和一问一答，反复练习记住这些信息。你可以使用标题、划了线的词和对要点所做的笔记来提问。
            </p>
            <p>
              回顾：最后一步积极地复习材料，主要是问你自己问题，只有当你确定答不出来时，重新阅读材料。
            </p>
          </li>
          <li>
            <p>3、高中生五大顶级记忆法。</p>
            <p>第一种、快速朗诵记忆法</p>
            <p>
              快速朗诵记忆法是指在初步理解文章段落后反复快速朗读，达到熟读的境界。科学研究证明缓慢朗诵记忆费时费力，而且记忆的留存在大脑皮层会很少，反之快速朗诵、多次朗诵，则可以对大脑记忆皮层产生连续的刺激，从而达到强化的记忆效果。
            </p>
            <p>适合对象：语文、政治、历史、英语</p>
            <p>第二种、理解记忆法</p>
            <p>
              研究表明如果想对一个新知识产生深刻记忆，其前提是对该知识有深刻的理解，理解越深刻则记忆越深刻。我们反对不求甚解的死记硬背的记忆方法，比如背诵政治课文前，要弄清课文的主旨、文章层次等，通过理解文章的特点来记忆背诵，效果就会好很多哦。
              适合对象：高中所有科目要背诵的知识点
            </p>
            <p>第三种、求同存异列表格记忆法</p>
            <p>
              高中很多科目里的各个知识点是类似的，比如地理科目，其区域地理部分很多章节的知识点是类似的，这时我们可以求同存异列表格的方法来记忆知识要点，而且直观有效。
              适合对象：地理、历史、生物、化学、政治
            </p>
            <p>第四种、利用关联词提示记忆法</p>
            <p>
              关联词是指：而，使，则等，关键词在议论文中出现的比例最高，关联词能把前句与后句串联起来，因此把握住这些关联词对于记忆背诵是特别有好处的，记住这些词能很快回忆起要背诵的知识点。
            </p>
            <p>适合对象：历史、地理、政治、生物、英语</p>
            <p>第五种、列提纲记忆法</p>
            <p>
              知识点的提纲就是其骨架和灵魂，课本的知识点编著者在阐述一个知识点时一定有其写作思路的，所以我们在背诵记忆知识点时可以先列个提纲，弄清背诵知识点的行文思路，顺藤摸瓜的记忆，这样的记忆是比较轻松的。
            </p>
            <p>适合对象：生物、地理、历史、政治</p>
          </li>
        </ul></van-tab
      >
      <van-tab title="考试状态" name="seven">
        <p>
          考试状态分为三个部分，第一个部分是做题的状态，第二部分是精神状态，第三部分是心理状态，只有这三种状态都调整好了，三种状态相互调和相互促进，至少能保证你可以正常发挥，当天时地利人和都具备时，超常发挥也未可知，取得高分也就在情理之中。
        </p>
        <ul>
          <li>
            0-53分（低）：你的数值低于社会平均值，面对考试你不太能轻松应对，很难发挥出平时的水平。
          </li>
          <li>
            53-74分（中）：你的数值处于社会平均值，面对考试你通常可以保持一颗平常心，能够正常发挥。
          </li>
          <li>
            75-100分（高）：你的数值高于社会平均值，面对考试你张弛有度，有时甚至还超常发挥。
          </li>
        </ul>
        <p>提升考试状态的方法：</p>
        <p>
          在考试前20天就应该有意识地引导自己开始适应这种状态，有的同学可能会问这样是不是太早了些，但我要告诉同学们的是这一点都不早，对于每个高中生来说真的太重要了，所以早些筹谋是最好的选择，调整好状态必须有一个前提那就是你必须要有一个系统性的复习计划，每天规定好复习进度，掌握好量，这样你才会有一个明确的方向。
        </p>
        <ul>
          <li>
            1、做题状态的调整：同学们有没有这样一种感觉，那就是如果某一学科的题目三天不做，再次去接触的时候或多或少都会有一种莫名的生疏感，这是因为你已经开始遗忘了，只是这种遗忘程度还不深，遗忘进程较慢，解决生疏的途径就是每天规定一定的量，在理解的基础上进行有针对性的练习，然后在练习的基础上进行归纳总结。
          </li>
          <li>
            2、精神状态的调整：按照你自己的作息习惯，以休息够为标准，合理安排自己的作息时间，当然，这里的“休息够”是有限制条件的，不可能让你一直睡到早上九点才起床吧。比如说，你平常早上六点钟就起了，晚上十二点半才睡，你一天才休息五个半小时，这么短的睡眠时间是不利于调整精神状态的，但这种作息规律你已经形成了习惯，短时间是改不过来的，所以需要提前几个星期，把你的这种生物钟慢慢改过来，你可以早上六点四十起床，晚上十一点就睡了，这样你的睡眠时间足够了，精神状态也就好了。有的同学就会说了，这样是没错了，但老师管得严，不可能这个时候起，一般五点多就起床了。当然，我这只是举个例子，睡眠时间还是要根据你的作息习惯来调整，一般情况下在高三末期大部分老师都不会过分要求的，这样你就有空间去调整自己了。
          </li>
          <li>
            3、心理状态的调整：多德森和耶克斯定理告诉我们，任何的活动都有其合适的动机水平，只有在合适的动机水平之下学习效率和效果才会提高。在这里我想告诉大家的是，复习过程中切记不要用力过猛，也不要消极懈怠，应该始终保持心态的平静，保持理性，在复习过程中要有一颗平常心，不要过于亢奋，也不要过于消极，这两种心理状态都不利于学习效果的提高，反而会影响你的学习进度，始终让你处于一种亚健康状态。
          </li>
        </ul>
      </van-tab>
      <van-tab title="亲子关系" name="eight">
        <p>
          亲子关系，不仅关乎一个家庭的快乐、和谐，也不仅仅是一个家庭教育的问题。实际上，它还决定着孩子的性格和思维方式，决定着孩子学习的好坏！
        </p>
        <ul>
          <li>
            0-60分（低）：你的数值低于社会平均值，你与父母的沟通并不太顺畅，父母对你的了解也不够充分。
          </li>
          <li>
            61-74分（中）：你的数值处于社会平均值，你与父母之间通常可以开诚布公，彼此尊重。
          </li>
          <li>
            75-100分（高）：你的数值高于社会平均值，你和父母之间的关系就像朋友一样融洽，学习和生活中的很多事情你们都可以交换意见。
          </li>
        </ul>
        <p>怎样提升亲子关系：</p>
        <ul>
          <li>
            <p>1.不当“法官”，学做“律师”</p>
            <p>
              在孩子出现一些状况时，父母要能控制住情绪，忍一忍，不要迫不及待地去当“法官”，武断的下结论。其实，孩子的内心世界是很丰富的，父母要正向的引导和教育孩子，第一要诀就是呵护其自尊，维护其权利，成为其信赖和相互尊敬的朋友。就像法庭上的“律师”对待自己的当事人一样，要先了解其内心需求，并始终以维护其合法权利为唯一宗旨。
            </p>
          </li>
          <li>
            <p>2.不当“裁判”，学做“拉拉队”</p>
            <p>任何人的人生都是由自己决定。</p>
            <p>
              虽然孩子小的时候是需要父母的呵护才能健康安全的成长，但是，最终父母是无法替代孩子的，同时也不该自作主张去当孩子人生的“裁判”，而应该给予孩子一种保持良好竞技状态的力量，即“拉拉队”的力量。做了孩子的“拉拉队”，既要善于发现和赞美孩子，还要引导他/她正确的面对挫折，在他/她遇到困难时当他/她的战友。这样更能帮助孩子建立自信心，而这正是家庭教育的核心之一。如此培养出的孩子才能在纷繁复杂的社会竞争中自力更生，应对各种各样的挑战。
            </p>
          </li>
          <li>
            <p>3.不当“教练”，学做“镜子”</p>
            <p>
              人只有认识自己才能突破自己，但通常只能依据他人的反馈来认识自己。对于孩子来说，这时父母的“反馈”作用就很重要了。不做“教练”，学做“镜子”，才能帮助孩子建立自我意识，才能让孩子不害怕父母的“权威”，将父母当成自己的朋友，进行良好的沟通。很多时候，忍一忍，缓一缓，等一等，给孩子倾诉和成长的机会，并保持有效地沟通，不用教育也许就能解决问题。
            </p>
          </li>
        </ul>
      </van-tab>
      <van-tab title="青春期问题" name="nine">
        <ul>
          <li>
            0-58分（低）：你的数值低于社会平均值，也许你正面临一些诸如早恋、沉迷游戏等一些青春期问题，你需要在自我成长、学业、情感、娱乐等问题上有一个正确的认识。
          </li>
          <li>
            59-72分（中）：你的数值处于社会平均值，即使你目前面对一些青春期的问题，你还是能够自我克服。
          </li>
          <li>
            73-100分（高）：你的数值高于社会平均值，你能够轻松应对青春期出现的一些问题，在这些问题上你也有一个正确的价值观念。
          </li>
        </ul>
        <p>如何面对青春期：</p>
        <ul>
          <li>
            1、面对爱情。以学习为重。作为高中生，我们的主要任务其实还是在学习上，千万不要将精力过多的投入到在追求异性中，否则很容易影响到我们的学习成绩。如若成绩不好，你未来又怎么谋求发展，企图以更好的人生去迎接美好的爱情呢！坦然面对变化。我们作为高中生，也是处在青春期，此时萌发对异性的一种好奇、心动的感觉是很正常的。出现这样的怦然心动或者朦朦胧胧的情感时，不需要当它是洪水猛兽，而应该自然而然的去接受它，好好的体验一下这种感觉，因为这是我们成长必须经历的。可适当探索。每个人对待自己不熟悉的领域，其实都有好奇之心，对于没有谈过恋爱的高中生，对爱情有所向往和好奇也是很正常的，这可以理解。我们平日里可以适当的探索一下这样的精神领域，不过建议不要轻易去触碰和尝试了。用日记的形式倾诉心事。如果你觉得内心对异性有波澜，这个时候不方便跟其他的人分享的话，那你还可以通过日记的形式来记录内心的情感变化，这会变成一件十分美好的事情。待到以后你长大了再回头看看原先的自己，你会对此莞尔一笑。控制好对异性的情感。虽然我们可能会对异性产生好感，但这未必就是爱情，此时的我们也许根本就分不清内心的那种充斥的满满的感觉到底是属于爱情的一部分，还是仅仅对异性的一种不一般的感觉罢了。所以，我们在分辨不清的时候应该学会控制自己的情感，不要让其泛滥。我们可以将这种情感的冲动转变为学习上的动力。
          </li>
          <li>
            2、怎样戒除网瘾。不要把上网作为逃避现实生活问题或者消极情绪的工具。上网之前先订目标。每次用两分钟时间想一想你要上网干什么，把具体要完成的任务列在纸上。不要认为这个两分钟是多余的，它可以为你节省的可能不止60分钟。正视沉迷于上网的危害。沉迷于上网，会使人迷失于虚拟世界，自我封闭，与现实世界产生隔阂，严重影响学习，甚至中断学业。久而久之，还会影响正常认知、情感和心理定位，导致人格的偏离，甚至发生不可设想的后果。有的因上网成瘾，神情恍惚，人格扭曲，无心读书，中途辍学；有的无钱上网，拦路抢劫，偷窃财物，导致违法犯罪。即使上网没有成瘾的人，如果每天12个小时坐在电脑面前，很可能会让自己少活8年以上时间。以新代旧。在戒除某种习惯时，这种习惯仍有很大的诱惑力，这是正常的心理现象。我们需要用一种新习惯来替代老习惯所产生的满足感。对于上网成瘾或者是正在戒网瘾的青少年，要注意培养新的爱好和习惯，要多参加一些自己喜欢的活动，多做一些自己感兴趣的事情，用自己的新行为和习惯来代替上网习惯。科学合理安排上网时间。每周最多3-5次，每次上网的时间不超过2小时，且连续操作1小时后应休息15分钟。尤其是夜晚上网时间不能过长。限制上网内容，每次上网前，一定先明确上网的任务和目标，把要完成的具体任务和内容列在纸上，不迷恋网上游戏。寻求别人的支持和帮助，戒除网瘾，寻求别人的支持和帮助非常必要，最好得办法是找到一个人帮助你克服这个问题。这种支持可来自同学、老师、朋友和家庭，可先向他们说明自己控制上网的计划，请他们监督；当网瘾出现时，请他们及时提示，帮助克服。
          </li>
        </ul>
      </van-tab>
    </van-tabs>
    <!-- <el-tabs v-model="activeTextName">
      <el-tab-pane label="学习目标" name="first"> </el-tab-pane>
      <el-tab-pane label="学习态度" name="second"> </el-tab-pane>
      <el-tab-pane label="学习习惯" name="third"> </el-tab-pane>
      <el-tab-pane label="学习效率" name="fourth">
        <p>
          学习效率是学习快慢的表示形式。做同一件事，你需要2个小时才能完成，而我只需要半个小时就可以完成，这就是效率。
        </p>
        <ul>
          <li>
            0-58分（低）：你的数值低于社会平均值，学习时你通常事倍功半，经常把老师留给的作业拖到很晚才能完成。
          </li>
          <li>
            59-74分（中）：你的数值处于社会平均值，通常能够准时的完成老师教给的任务，也能留出一定的时间进行课外阅读。
          </li>
          <li>
            75-100分（高）：你的数值高于社会平均值，学习时你通常事半功倍，能够合理的安排好娱乐和学习的时间。
          </li>
        </ul>
        <ul>
          <li>
            1、分解学习任务，使用80/20法则管理学习过程。大家都非常熟悉80/20法则，其实这个法则同样可以运用到学习中来。80%的产出，是由20%的工作带来的。我们在学习中会遇到无从下手的时候，这时我们只需做一两件事，这一两件事是达到目的的关键。通过反复的学习和思考，就会带来在这个问题上的突破。这就是分解学习任务，把学习的重点画出来，着重学习理解。那么学习中普遍的，推演的部分便迎刃而解。例如弹吉他，不应该纠结弹奏的模式，而是认真练习基本的和弦，以及和弦之间的过渡，等练习的连贯之后自然会取得大的进步。
          </li>
          <li>
            2、全神贯乎，番茄学习法提高效率。学习时全神贯注非常重要，可是做起来却很难。你会被突然想起的手机打断，有时也会因为外界的嘈杂而影响。为了增加学习的专注度，使用番茄学习法或许能有一定效果。我们排除一切干扰学习的外界因素，并在短时间内专注一件事，大概持续20--25分钟，然后休息3--5分钟。这样我们在学习时，把学习分成若干个时段，每个时段学习25分钟，休息5分钟。每四个时段多休息一会。这样能使我们在短时间内集中精神完成信息的收集，又不会因为长期的学习感到乏累，提高了学习的有效性。在短暂的休息中，也能得到必要的放松和思考，缓冲学习的知识，避免造成积压。
          </li>
          <li>
            3、通过实践学习，沉浸式学习法。研究显示通过阅读学习只能学到其中10%的知识：通过听和看能学到其中20%的知识；通过演示能学到其中的50%的知识；学完后练习能学到其中70%的知识；学完后立即使用就能学到90%的知识。很明显沉浸式学习法是最有效的学习方法，只有把学习的东西付诸于实践中，才能巩固学习反射，加强学习效果。就像学习踢足球，没有人会通过专业书籍变成优秀的运动员。你必须上场去练习，在比赛中提高踢球的技能。站在场边看十场比赛，不加实践练习，你也学不会一次停球。
          </li>
          <li>
            4、充足睡眠。 睡眠对于学习意义重大。我们在清醒时学习新事物收集新信息，并加强这些记忆。睡眠就是一种创造性记忆的过程，我们清醒时受到的刺激和学习的知识会在睡眠中得到加强。科学家认为，人的大脑是可以改变结构和组织，也就是大脑的可塑性。我们通过睡眠的休息状态，让大脑对学习的新知识新信息进行处理强化,形成知识记忆。
            当然充足的睡眠也是保证未来学习的可持续性,困倦的大脑
            很难集中精神进行学习。保证充足的睡眠也是缓解压力和放松身体的有效方式。毕竟学习是一个需要全身能动的过程。
          </li>
        </ul>
      </el-tab-pane>

      <el-tab-pane label="学习策略和方法" name="five"> </el-tab-pane>
      <el-tab-pane label="考试状态" name="seven">
       
      </el-tab-pane>
      <el-tab-pane label="亲子关系" name="eight">
       
      </el-tab-pane>

      <el-tab-pane label="青春期问题" name="nine">
        
      </el-tab-pane>
    </el-tabs> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTextName: "first"
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style lang="scss" scoped>
.learn_status_text {
  margin-top: 0.5rem;
  margin-bottom: 1.4rem;
  .van-tabs__content {
    background: #fff;
    padding: 0 0.3rem;
  }
  p {
    line-height: 26px;
    margin-bottom: 10px;
  }
  ul {
    li {
      margin-bottom: 10px;
      line-height: 26px;
    }
  }
}
.van-tabs {
  background: #fff;
  padding-bottom: 1rem;
}
.van-tab {
  padding: 0 10px;
}
.van-tabs__wrap {
  margin-bottom: 0.35rem;
}
.van-tabs__line {
  background-color: #910000;
}
</style>
